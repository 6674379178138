<template>
  <div class="">
    <label for="" class="base-modal__label">{{ label }}</label>
    <v-text-field v-bind="$attrs" height="50px" plain outlined class="base-modal__input" v-on="$listeners">
      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'BaseTextField',
  inheritAttrs: false,
  props: {
    label: String
  },
  data () {
    return {
      content: this.value
    }
  }
}
</script>

<style scoped lang="scss">

.v-text-field ::v-deep input {
  color: #243674;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.05em;
}

.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 36px;
}

.v-text-field ::v-deep .v-input__prepend-inner {
  margin-top: 14px;
}

.base-modal {
  &__label {
    font-size: 16px;
    line-height: 18px;
    color: #C4C4C4;
    letter-spacing: -0.06em;
  }

  &__input {
    border-radius: 10px !important;
  }
}

.v-text-field ::v-deep .v-input__slot {
  margin-bottom: 2px;
}

.v-text-field ::v-deep .v-text-field__details {
  margin-bottom: 2px;
  padding: 0 8px;
}

.v-text-field ::v-deep .v-input__append-inner {
  margin-top: 13px;
}
</style>
